.mat-tab-links,
.mat-tab-link {
  opacity: 1 !important;
  color: var(--primary) !important;
  font-weight: normal !important;
}

.mat-tab-link:focus {
  background-color: transparent !important;
}

.mat-menu-panel {
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.2) !important;
  border-radius: 7px !important;
  margin-top: 6px;
  min-width: 165px !important;
  position: absolute;
  overflow: unset !important;
  right: -8px;
}

.mat-menu-panel:before {
  content: "";
  border-style: solid;
  border-width: 10px 12px;
  position: absolute;
  border-color: transparent transparent white transparent;
  right: 12px;
  top: -19px;
}

//login page
.login {
  .mat-form-field-suffix {
    color: white !important;
  }

  .mat-form-field:not(.ng-dirty.ng-invalid) {

    .mat-form-field-label,
    input {
      color: white !important;
      caret-color: white !important;
    }

    .mat-form-field-underline,
    .mat-form-field-ripple {
      background-color: white !important;
    }
  }

  .mat-form-field.ng-dirty.ng-invalid {
    .mat-form-field-ripple {
      background-color: var(--warn) !important;
    }

    .mat-form-field-label,
    input {
      color: var(--warn) !important;
      caret-color: var(--warn) !important;
    }
  }

  .mat-form-field.ng-touched.ng-invalid {
    .mat-form-field-ripple {
      background-color: var(--warn) !important;
    }

    .mat-form-field-label,
    input {
      color: var(--warn) !important;
      caret-color: var(--warn) !important;
    }
  }
}

//factchecker edit page
.payment-edit {
  .list-dropdown .mat-form-field-infix {
    border: none;
  }

  mat-form-field {
    width: 350px;
  }

  .unclaimed,
  .to_be_paid .mat-select-value {
    color: var(--stat-red) !important;
  }

  .in_process,
  .in_process .mat-select-value {
    color: var(--stat-orange) !important;
  }

  .paid,
  .paid .mat-select-value,
  .claimed {
    color: var(--stat-green) !important;
  }
}

//manager categories page
.manager-cats {
  .mat-form-field .mat-form-field-infix {
    border: none;
    padding: unset;
  }

  .mat-form-field {
    width: 55px;
  }
}

//table
th:not(:first-child) div.mat-sort-header-container {
  display: flex;
  justify-content: center;
}

.table-wrapper {
  .mat-button-wrapper {
    outline: none;
  }

  .mat-button-ripple.mat-ripple,
  .mat-button-focus-overlay {
    background: transparent;
  }

  .mat-sort-header-sorted .mat-sort-header-arrow {
    color: var(--gray-text);
    transform: translateY(0px) !important;
  }
}

.my-priorities {
  .mat-form-field {
    font-weight: inherit;

    .mat-form-field-infix {
      border: none;
      padding: unset;
      height: 30px;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding: unset;
  }

  .mat-form-field-underline {
    top: 40px;
  }

  .mat-input-element {
    position: absolute;
    top: 20px;
  }

  .mat-form-field-label-wrapper {
    top: 0;
  }
}

.mat-form-field-disabled .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.17) !important;
  background-image: none !important;
}

.questions {
  .mat-accordion .mat-expansion-panel:last-of-type {
    background-color: var(--bg);
    min-height: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 6%);
    border-radius: 10px;
  }

  .mat-expansion-panel-header {
    padding: 0 20px 0 0;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
    background: white;
  }
}

//edit-round
.edit-round,
.final-round {

  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type {
    padding-left: 0;
    width: 100px;
    text-align: center;
    line-height: 12px;

    .check-all {
      width: 30px;
    }
  }

  .text-header {
    padding-left: 15px;
  }

  th.mat-header-cell {
    border-bottom: 1px solid rgba(0, 0, 0, 0.4) !important;
  }

  th {
    &.question-type {
      width: 80px;
    }

    &.point-value {
      width: 100px;
    }

    &.category {
      width: 120px;
    }

    &.sub-category {
      width: 130px;
    }
  }

  table.mat-table tr td {
    padding: 5px 15px;

    &:last-child {
      padding-right: 0;
      padding-left: 0;
    }

    &:first-child {
      border: transparent;
    }
  }

  table.mat-table tbody tr {
    position: relative;

    &.wager-row-content > td:first-child {
      border-left: 2px solid #a231be !important;
      left: -2px;

      &:after {
        content: "";
        display: block;
        height: 20px;
        width: 110%;
        left: 0;
        bottom: -18px;
        border: 2px solid #a231be;
        position: absolute;
        border-radius: 0 0 0 15px;
        border-top: none;
        border-right: none;
        column-span: all;
      }
    }

    &.wager-row-content > td:last-child {
      border-right: 2px solid #a231be !important;

      &:after {
        content: "";
        display: block;
        height: 20px;
        width: calc(100% + 2px) !important;
        left: 0;
        bottom: -18px !important;
        border: 2px solid #a231be;
        position: absolute;
        border-radius: 0 0 15px 0;
        border-top: none !important;
        border-left: none;
        column-span: all;
      }
    }

    &.wager-row-content > td:not(:first-child):not(:last-child) {
      &:after {
        content: "";
        display: block;
        height: 20px;
        width: 100% !important;
        left: 0;
        bottom: -18px !important;
        border: 2px solid #a231be;
        position: absolute;
        border-top: none !important;
        border-left: none;
        border-right: none;
        column-span: all;
      }
    }
  }

  table.mat-table tbody tr td {
    border: none !important;
    position: relative;

    &.wager-row {
      text-align: left;

      &:before {
        height: 85%;
        content: "";
        display: block;
        width: calc(100% + 2px);
        border: 2px solid #a231be;
        position: absolute;
        top: 7px;
        border-radius: 15px 15px 0 0;
        border-bottom: none;
        left: -1px;
      }
    }

    &:not(&.checkbox, &.wager-row) {
      &:after {
        content: "";
        display: block;
        width: calc(100% - 30px);
        border-top: 1px solid rgba(0, 0, 0, 0.17);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      &:last-of-type {
        &:after {
          width: calc(100%);
        }
      }
    }
  }

  .mat-select {
    &.small-select {
      width: 40%;
    }

    &.medium-select {
      width: 60%;
    }
  }
}

tr.active {
  border-bottom: 0.1px solid black !important;
}

//selector
.selector {
  .mat-select-arrow {
    color: var(--primary);
  }

  .template-select {
    .mat-select-arrow {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid;
      margin: 0 3px;
    }
  }
}

// project-modal

.project-modal,
.change-game-modal {
  .mat-dialog-container {
    width: 660px;
    padding: 0 !important;
    border-radius: 10px !important;
    height: 470px !important;
    overflow: hidden !important;
    margin: 0 auto !important;
  }

  .md-dialog-container {
    width: 92%;
    margin: 0 auto !important;
  }

  .mat-button-wrapper {
    width: 150px;
  }
}

.project-modal {
  .mat-dialog-container {
    &::before {
      content: "";
      position: fixed;
      width: 12px;
      height: 470px !important;
      background-color: #a231be;
      border-radius: 10px 0 0 10px;
    }
  }

  .mat-dialog-actions {
    flex-direction: column;

    .mat-paginator {
      align-self: flex-end;
    }
  }
}

.change-game-modal {
  .mat-dialog-container {
    height: 48vh !important;

    .md-dialog-container {
      mat-dialog-content {
        height: 30vh !important;
      }
    }
  }
}

.add-question-modal {
  height: 90vh !important;

  .mat-dialog-container {
    height: 90% !important;
  }
}

.login {
  .mat-form-field-wrapper {
    .mat-form-field-underline {
      /*change color of underline*/
      color: #fff !important;
    }

    .mat-form-field-flex {
      .mat-form-field-infix {
        input {
          color: #fff !important;
        }
      }
    }
  }

  mat-label,
  .mat-placeholder-required {
    /*change color of label*/
    color: white !important;
  }

  .mat-focused .mat-form-field-label {
    /*change color of label*/
    color: white !important;
  }

  .mat-form-field-underline {
    /*change color of underline*/
    background-color: #fff !important;
  }

  .mat-form-field-ripple {
    background-color: #fff !important;
  }
}

.error-hint {

  mat-hint,
  mat-label {
    color: red !important;
  }

  .mat-form-field-wrapper {
    .mat-form-field-underline {
      /*change color of underline*/
      background: red !important;
    }
  }
}

// draggable mat table
.draggable-table {
  thead tr {
    padding: 0 10px !important;
  }

  thead tr {
    th {
      border: none !important;
    }

    border-top: 1px solid rgba(0, 0, 0, 0.4);
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    font-size: 10px;
  }

  tbody {

    th.mat-header-cell,
    td.mat-cell {
      border: none;
    }

    tr td {
      border: none !important;
      position: relative;

      td:nth-child(1),
      td.actions,
      td.flag {
        width: fit-content;
      }

      &:not(td:nth-child(1), td.actions, td.flag) {
        &:after {
          content: "";
          display: block;
          width: calc(100% - 20px);
          border-top: 1px solid rgba(0, 0, 0, 0.17) !important;
          position: absolute;
          bottom: 11px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }
  }
}

.month-calendar {
  mat-calendar-header {
    visibility: collapse;
    display: none;
  }

  .mat-calendar-table {
    .mat-calendar-table-header {
      tr {
        th {
          font-weight: 500;
        }

        .mat-calendar-table-header-divider {
          display: none;
        }

        th:not(th:nth-last-child(-n+2)) {
          color: #253342;
        }
      }
    }

    .mat-calendar-body {

      tr:nth-child(1) {
        td:nth-child(1) {
          visibility: collapse;
        }

        td:only-child {
          display: none;
        }
      }

      tr td {
        div:not(.mat-calendar-body-selected) {
          font-weight: 400;

          &:hover {
            background: rgba(204, 0, 255, 0.09) !important;
            color: #000;
          }
        }

        .mat-calendar-body-cell-content {
          border-radius: 2px;
          font-weight: 400;
        }

        .mat-calendar-body-selected {
          background-color: #A231BE;
          border: none;
        }
      }
    }
  }
}

.range-calendar {
  .mat-calendar-table {
    .mat-calendar-body {

      tr {

        .mat-calendar-body-range-end,
        .mat-calendar-body-range-start {
          background-color: #A231BE;
          border: none;

        }

        .mat-calendar-body-range-end {
          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            left: -18%;
            top: 18px;
            border-style: solid;
            border-width: 6px 10.4px 6px 0;
            border-color: transparent #A231BE transparent transparent;
          }
        }

        .mat-calendar-body-range-start {
          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            left: 95%;
            top: 18px;
            border-width: 5px 0 5px 10px;
            border-color: transparent transparent transparent #A231BE;
          }

        }
      }
    }
  }
}


.underline-none {
  .mat-form-field-underline {
    display: none;
  }
}

.not-red-fields {
  .mat-form-field-invalid.not-red {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-form-field-label-wrapper {
            label {
              color: rgba(0, 0, 0, 0.54) !important;

              span {
                color: rgba(0, 0, 0, 0.54) !important;
              }
            }
          }
        }
      }

      .mat-form-field-underline {
        .mat-form-field-ripple {
          background-color: #7a3b9b;
        }
      }
    }
  }
}
