//@import "node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.error {
  background-color: var(--warn) !important;

  .mat-button-wrapper,
  .mat-simple-snack-bar-content {
    color: #FFFFFF;
  }
}

.title {
  color: var(--titleColor);
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 30px !important;
}

strong {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.tab-item {
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 33px;
}

.activeLink {
  color: var(--primary);
  font-weight: 500 !important;
  background-color: transparent !important;
}

.title-height {
  height: 40px;
}

.page-title {
  font-size: 20px;
  font-weight: 300;
  color: var(--gray-text);
}

.fz-14-text {
  color: var(--textColorBox);
  font-weight: normal !important;
  font-size: 14px !important;
}

.fz-14 {
  font-size: 14px;
}

.fz-20 {
  font-size: 20px;
}

.fz-20-text {
  font-weight: normal !important;
  font-size: 20px !important;
  color: var(--textColorBox) !important;
}

.font-weight-500 {
  font-weight: 500;
}

.fz-12 {
  font-size: 12px;
}

.mat-select-value {
  color: var(--textColorBox) !important;
}

.color-orange {
  color: var(--stat-orange) !important;
}

.color-green {
  color: var(--stat-green) !important;
}

.color-red {
  color: var(--stat-red) !important;
}

.err-message {
  color: #FF0000 !important;
  font-size: 10px !important;
  font-weight: 400;
  z-index: 10000;
}

.outline-none {
  outline: none !important;
}

.align-middle {
  text-align: center !important;
}

.align-right {
  text-align: end !important;
}

.align-left {
  text-align: start !important;
}

.success-snack,
.error-snack {
  color: white !important;

  .mat-simple-snackbar-action {
    color: white !important;
  }
}

.success-snack {
  background-color: var(--primary-light) !important;
}

.error-snack {
  background-color: var(--danger) !important;
}

.no-click {
  pointer-events: none;
}

.readonly-select {
  &.mat-select {
    pointer-events: none;

    .mat-select-arrow-wrapper {
      opacity: .4;
    }
  }
}

.fs-12 {
  font-size: 12px;
}

.wavesurfer-handle {
  background-color: #6620AB !important;
}

.spc-inactive {
  pointer-events: none;
  opacity: .7;
}

.white-bg {
  background-color: white;
  min-height: 100vh;
  width: 100vw;
}

.sp {
  position: absolute !important;
  left: 46%;
  top: 30%;
}

.box-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.question-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.no-resize {
  textarea {
    resize: none !important;
  }
}

.image-delete-icon {
  background: rgba(white, 0.7);
  width: 40px;
  height: 30px;
  bottom: calc(140px - 110px);
  left: 75%;
  position: relative;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;

  mat-icon {
    top: 4px;
    left: 4px;

    &:hover {
      cursor: pointer;
    }
  }
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  margin-right: 4px;
}

body::-webkit-scrollbar-track {
  background: #00000007 !important;
  border-radius: 5px !important;
  margin: 2px 0;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #00000007 !important;
  border-radius: 5px !important;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #888888 !important;
  border-radius: 5px !important;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #525252 !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #00000007;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #525252;
}

* {
  scrollbar-width: thin !important;
  scrollbar-color: #888888 rgba(0, 0, 0, 0.034) !important;

  .ql-container {
    height: auto;
  }
}

.mat-error {
  font-size: 12px !important;
}

.date-field {
  .mat-form-field-flex {
    background-color: var(--bg) !important;
  }
}

.reasons {
  .mat-expansion-panel-header,
  .simplebar-content-wrapper {
    padding: 0 21px !important;
  }
}

.overflow-y {
  overflow-y: auto;
}

.main {
  padding-bottom: 10px;
}

.question-box {
  app-factcheck-choose-from-ten,
  app-factcheck-multiple-choice,
  app-factcheck-true-false,
  app-factcheck-pick3,
  app-factcheck-answer-fast,
  app-factcheck-pick-form-image,
  app-factcheck-eo,
  app-fact-check-range,
  app-factcheck-puz-matching,
  app-factcheck-picture-round,
  app-factcheck-choose-from-list,
  app-factcheck-one-column,
  app-factcheck-two-column,
  app-factcheck-fill-in-blank {
    width: 100%;
  }
}

mat-chip {
  word-break: break-all !important;
  height: auto !important;
}
