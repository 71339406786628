button {
  outline: none !important;
}

.button-primary,
.button-disabled,
.auto-width-button-primary {
  background: linear-gradient(98.46deg, #d615e9 -31.7%, #8241a3 51.95%, #2b78f3 130.13%) !important;
  color: white !important;
  border-radius: 20px !important;
  padding: 2px 25px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

.button-primary {
  cursor: pointer;
}

.button-disabled {
  background: #d7d7d7 !important;
  cursor: auto !important;
}

.auto-width-button-primary {
  width: auto !important;
  padding: 0 20px !important;
}

.button-border {
  background: white;
  color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  border-radius: 20px !important;
  padding: 2px 25px !important;
  min-width: 140px !important;
}

.button-danger {
  background-color: var(--warn) !important;
  color: white !important;
  border-radius: 20px !important;
  padding: 2px 25px !important;
  min-width: 140px !important;
}

.button-accept {
  background-color: var(--accent) !important;
  color: white !important;
  border-radius: 20px !important;
  padding: 2px 25px !important;
  min-width: 140px !important;
}

.button-cancel {
  background-color: var(--bgDark) !important;
  color: white !important;
  border-radius: 20px !important;
  padding: 2px 25px !important;
  min-width: 140px !important;
}

.back-btn {
  width: 35px;
}

.rm-image {
  min-width: 24px !important;
}
