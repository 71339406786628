@import "~@angular/material/theming";
@import './theme1-colors.scss';

// Angular Material typography
$app-typography: mat-typography-config(
  $font-family: "Roboto, sans-serif",
  $headline: mat-typography-level(30px, 37px, 600),
  $title: mat-typography-level(25px, 37.5px, 600),
  $body-1: mat-typography-level(17px, 27px, 300),
  $body-2: mat-typography-level(18px, 27px, 500),
  $button: mat-typography-level(15px, 19px, 400),
  $subheading-2: mat-typography-level(18px, 1.3 * 18px, 400),
  $subheading-1: mat-typography-level(15px, 20px, 400),
  $caption: mat-typography-level(12px, 18px, 400)
);

@include mat-core($app-typography);

// Define the default theme (same as the example above).
$theme1-theme-primary-palette: mat-palette($theme1-primary, 600);
$theme1-theme-primary-light-palette: mat-palette($theme1-primary, 500);
$theme1-theme-accent-palette: mat-palette($theme1-accent, 600);
$theme1-theme-warn-palette: mat-palette($theme1-warn, 600);
$theme1-theme: mat-light-theme($theme1-theme-primary-palette, $theme1-theme-accent-palette, $theme1-theme-warn-palette);

.theme1{
  @include angular-material-theme($theme1-theme);
  --bg: white;
  --bgDark: #E3E3E3;
  --textColor: #818181;
  --textColorBox: #656565;
  --stat-orange: #FFB74B;
  --stat-green: #3aaf5b;
  --stat-red:#FF0000;
  --gray-text: #464646;
  --darkText: #000000;
  --themed-color: #14AAFF;
  --default-color: #B414FF;
  --private-color: #FF14BD;
  --city_trivia-color: #F4BF01;
  --warfare-color: #14D928;
  --primary: #{mat-color($theme1-theme-primary-palette)};
  --primary-light: #{mat-color($theme1-theme-primary-light-palette)};
  --primaryContrast: #{mat-color($theme1-theme-primary-palette, default-contrast)};
  --accent: #{mat-color($theme1-theme-accent-palette)};
  --accentContrast: #{mat-color($theme1-theme-accent-palette, default-contrast)};
  --warn: #{mat-color($theme1-theme-warn-palette)};
}
