.white-box {
  background-color: var(--bg);
  border-radius: 20px;
  min-height: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 6%);
}

.white-main-box {
  background-color: var(--bg);
  border-radius: 20px 20px 0 0;
  height: 100%;
  min-height: 94vh;
}

.wb-radius {
  border-radius: 20px;
  min-height: 50px;
}

.dark-box {
  background-color: var(--bgDark);
  border-radius: 20px;
  min-height: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.small-box {
  background-color: var(--bg);
  min-height: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 6%);
  border-radius: 10px;
}

.image-box {
  background-color: var(--bgDark);
  border-radius: 10px;
  cursor: pointer;
  height: 90px;
}

.circle-image-box{
  background-color: var(--bgDark);
  border-radius: 50%;
  cursor: pointer;
  height: 140px;
}

.image-box {
  background-color: var(--bgDark);
  border-radius: 10px;
  cursor: pointer;
  height: 140/1.7;

  &-x1280 {
    width: 140px;
  }

  &-x122 {
    width: 98px;
  }

  &-x364 {
    width: 162px;
  }

  &-x750 {
    width: 51px;
  }

  &-x1920 {
    width: 289px;
  }

  &-x990 {
    width: 198px;
  }

  &-x520 {
    width: 85px;
  }

  &-x618 {
    width: 273px;
  }

  &-x506 {
    width: 90px;
  }
}
